import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`🏎 Quickstart`}</h1>
    <ol>
      <li parentName="ol">{`Create an account and log in @ `}<a parentName="li" {...{
          "href": "https://pol.is/home"
        }}>{`https://pol.is/home`}</a></li>
      <li parentName="ol">{`Create a new `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{``}</li>
      <li parentName="ol">{`Add a title and description in `}<a parentName="li" {...{
          "href": "/conversation-configuration",
          "title": "conversation configuration"
        }}>{`conversation configuration`}</a>{``}</li>
      <li parentName="ol">{`Optionally add 15-40 `}<a parentName="li" {...{
          "href": "/seed-comments",
          "title": "seed comments"
        }}>{`seed comments`}</a>{``}</li>
      <li parentName="ol">{`Configure `}<a parentName="li" {...{
          "href": "/Moderation",
          "title": "👹 Moderation"
        }}>{`👹 Moderation`}</a>{` settings`}</li>
      <li parentName="ol">{`Grab the link in `}<a parentName="li" {...{
          "href": "/distribute",
          "title": "distribute"
        }}>{`distribute`}</a>{``}</li>
      <li parentName="ol">{`Send link to `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{` or `}{`[embed it on your own page]`}{`(Embedding Polis on Your Web Property)`}</li>
      <li parentName="ol">{`Open one tab with `}<a parentName="li" {...{
          "href": "/monitor",
          "title": "monitor"
        }}>{`monitor`}</a>{` view to observe participation`}</li>
      <li parentName="ol">{`Open another tab with `}<a parentName="li" {...{
          "href": "/report",
          "title": "report"
        }}>{`report`}</a>{` view to observe outcomes`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      